<template>
    <div>
        <div>
            <template>
                <v-container>
                    <h4 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center">
                        <strong> {{ branchInformation.branchName }} </strong> 
                    </h4>
                    <h4 v-else class="headline mt-5 primary--text text-center">
                        <strong>  {{ branchInformation.branchNameNepali }}  </strong>
                    </h4>
                    <div id="MainContainer">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-card class="mt-5"
                                        id="slider-card"
                                        max-height="550"
                                        width="100%"
                                        :elevation="24"
                                        style="background:white">
                                    <img :src="branchInformation.logo"
                                         id="hotellogo" />
                                </v-card>
                            </v-col>
                            <v-col class="box" cols="12" sm="2">
                                <v-row class="mt-10">
                                    <v-card id="box">
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" class="box"
                                                    @click="$router.push('/branch')"
                                                    color="#077dfa"
                                                    elevation="5">
                                                <p id="titleForBox">Profile</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">person</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('profile')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    class="box"
                                                    @click="$router.push('/MuseumService')"
                                                    color="#006400"
                                                    elevation="5">
                                                <p id="titleForBox">Ticket Category</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('ticket_category')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    @click="$router.push('/MuseumCustomerList')"
                                                    color="#FFA500"
                                                    elevation="5">
                                                <p id="titleForBox">Ticket Issue</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">directions_run</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('ticket_issue')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox"
                                                    @click="$router.push('/MuseumEmployeeList')"
                                                    color="#ff770f"
                                                    elevation="5">
                                                <p id="titleForBox">Employee</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('employee')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-container>
                        <h4 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center">
                            Visitors in {{ branchInformation.branchName }}
                        </h4>
                        <h4 v-else class="headline mt-5 primary--text text-center">
                            {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                        </h4>
                        <v-row>
                            <v-col cols="12" md="12" class="mt-3">
                                <v-card elevation="5">
                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="barsData"
                                                    :options="chartOptions"
                                                    style="height: 400px; " />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <hr style="margin-top: 60px;" />
                    <!--<v-row>
      <v-col cols="12" md="6">
        <h1 class="headline mt-5 primary--text" style="margin-left:20px">
          {{ branchInformation.branchName }} Visitors
        </h1>
      </v-col>
      <v-col cols="12" md="6">
        <h1 class="headline mt-5 primary--text">
          {{ branchInformation.branchName }} Employees
        </h1>
      </v-col>
    </v-row>-->
                    <v-container>
                        <v-row>

                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text">
                                    Visitors in {{ branchInformation.branchName }}
                                </h1>
                                <h1 v-else class="headline mt-5 primary--text">
                                    {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                                </h1>
                                <div slot="table-actions">
                                    <v-btn @click.stop="addModal" color="primary">{{$t('ticket_issue')}}</v-btn>
                                </div>
                                <v-dialog max-width="500px" v-model="dialogDelete">
                                    <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
                                </v-dialog>
                                <vue-good-table :columns="columns"
                                                :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPageDropdown: [5,10,15,20]

                                    }"
                                                :rows="rows"
                                                :totalRows="customerData.totalRecords"
                                                @on-page-change="onPageChange"
                                                @on-per-page-change="onPerPageChange"
                                                @on-sort-change="onSortChange"
                                                mode="remote"
                                                styleClass="vgt-table condensed">
                                    <template slot="table-column" slot-scope="props">
                                        <span>
                                            {{ $t(props.column.label) }}
                                        </span>
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'flightDateTime'">
                                            <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                                        </span>
                                        <span v-else-if="props.column.field == 'actions'">
                                            <v-icon @click.stop="editItem(props.row, 'CustomerForm')" class="mr-2" small>edit</v-icon>
                                            <v-icon @click.stop="deleteItem(props.row)" small>delete</v-icon>
                                        </span>
                                    </template>
                                </vue-good-table>
                                <v-dialog fullscreen
                                          hide-overlay
                                          persistent
                                          transition="dialog-bottom-transition"
                                          v-model="snackbar.formModal">
                                    <v-card>
                                        <!-- <component :is="snackbar.dynamicComponent.current"
                                   @dialogueClose="dialogueClose"
                                   @formResponse="formResponse"
                                   v-bind="customerData.updateItem"
                        ></component>-->
                                        <CustomerForm @dialogueClose="dialogueClose"
                                                      @formResponse="formResponse"
                                                      v-if="snackbar.createFormComponent"></CustomerForm>
                                        <CustomerEditForm :editItemIndex="customerData.editItemIndex"
                                                          @dialogueClose="dialogueClose"
                                                          @formResponse="formResponse"
                                                          v-if="snackbar.editFormComponent"></CustomerEditForm>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text">
                                    Employees in {{ branchInformation.branchName }}
                                </h1>
                                <h1 v-else class="headline mt-5 primary--text">
                                    {{ branchInformation.branchNameNepali }} मा कर्मचारी
                                </h1>
                                <div slot="table-actions">
                                    <v-btn @click.stop="addEmployee" color="primary">{{$t('add_employee')}}</v-btn>
                                </div>
                                <!--<v-dialog max-width="500px" v-model="dialogDelete">
        <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
    </v-dialog>-->
                                <vue-good-table :columns="empColumns"
                                                :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPageDropdown: [5,10,15,20]

                                    }"
                                                :rows="empRows"
                                                :totalRows="customerData.totalRecords"
                                                @on-page-change="onPageChange"
                                                @on-per-page-change="onPerPageChange"
                                                @on-sort-change="onSortChange"
                                                mode="remote"
                                                styleClass="vgt-table condensed">
                                    <template slot="table-column" slot-scope="props">
                                        <span>
                                            {{ $t(props.column.label) }}
                                        </span>
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'flightDateTime'">
                                            <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                                        </span>
                                        <span v-else-if="props.column.field == 'actions'">
                                            <v-icon @click.stop="editEmployee(props.row, 'EmployeeForm')" class="mr-2" small>edit</v-icon>
                                            <v-icon @click.stop="deleteItem(props.row)" small>delete</v-icon>
                                        </span>
                                    </template>
                                </vue-good-table>
                                <v-dialog fullscreen
                                          hide-overlay
                                          persistent
                                          transition="dialog-bottom-transition"
                                          v-model="snackbar.eformModal">
                                    <v-card>

                                        <EmployeeForm @dialogueClose="dialogueClose"
                                                      @formResponse="formResponse"
                                                      v-if="snackbar.createEmployeeComponent"></EmployeeForm>
                                        <!--<CustomerEditForm :editItemIndex="customerData.editItemIndex"
              @dialogueClose="dialogueClose"
              @formResponse="formResponse"
              v-if="snackbar.editEmployeeComponent"></CustomerEditForm>-->
                                    </v-card>
                                </v-dialog>




                            </v-col>
                        </v-row>
                    </v-container>

                    <v-container>
                        <v-row>
                            <!--<v-col cols="12" md="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        Monthly Visitors in {{ branchInformation.branchName }}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="customerDatas"
                                    :options="chartOptions"
                                    style="height: 400px;" />
                        </div>
                    </template>
                </v-card>
            </v-col>-->

                            <v-col cols="12" md="12">
                                <v-card class="pa-5" elevation="5">
                                    <v-expansion-panels hover>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row align="center" class="mt-4">
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-menu :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                class="col-sm-6 col-md-4"
                                                                min-width="290px"
                                                                offset-y
                                                                transition="scale-transition"
                                                                v-model="snackbar.menu">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field clearable
                                                                              :label="$t('select_date')"
                                                                              prepend-inner-icon="event"
                                                                              readonly
                                                                              dense
                                                                              outlined
                                                                              v-model="serverParams.date"
                                                                              v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="serverParams.date"></v-date-picker>

                                                        </v-menu>
                                                    </v-col>

                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-autocomplete v-model="serverParams.reportTypeID"
                                                                        :items="monthTypes"
                                                                        clearable
                                                                        dense
                                                                        outlined
                                                                        item-text="value"
                                                                        item-value="id"
                                                                        :label="$t('report_type')">
                                                        </v-autocomplete>
                                                    </v-col>

                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-btn color="primary" dark v-on:click="searchInput" class="mr-1">
                                                        <i class="material-icons">search</i>{{$t('search')}}
                                                    </v-btn>
                                                    <v-btn color="error" dark @click="clearInput">
                                                        <i class="material-icons">clear</i>{{$t('clear')}}
                                                    </v-btn>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center">
                                        Visitors in {{ branchInformation.branchName }}
                                    </h1>
                                    <h1 v-else class="headline mt-5 primary--text text-center">
                                        {{ branchInformation.branchNameNepali }} मा आगन्तुकहरू
                                    </h1>
                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="customerReportData"
                                                    :options="chartOptions"
                                                    style="height: 400px;" />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-card class="pa-5" elevation="5">
                                    <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        Foreignier & Domestic Visitors
                                    </h1>
                                    <h1 v-else class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                            विदेशी र स्वदेशी आगन्तुकहरू
                                    </h1>
                                    <template>
                                        <GChart type="PieChart"
                                                :options="options"
                                                :data="dataS"
                                                style="height:500px"
                                                align="center" />
                                    </template>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-card class="pa-5" elevation="5">
                                    <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        Country Wise Tourist Visit Per Month
                                    </h1>
                                    <h1 v-else class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        देश अनुसार पर्यटक भ्रमण प्रति महिना
                                    </h1>
                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="monthlyCustomerVisitCountrywiseData"
                                                    :options="chartOptions"
                                                    style="height: 500px" />
                                        </div>
                                    </template>
                                </v-card>

                            </v-col>


                        </v-row>
                    </v-container>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-card class="pa-5" elevation="5">
                                    <v-expansion-panels hover>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row align="center" class="mt-4">
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-text-field v-model="serverParams.minAge"
                                                                      :label="$t('min_age')"
                                                                      type="Number"
                                                                      dense
                                                                      outlined
                                                                      clearable>

                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-text-field v-model="serverParams.maxAge"
                                                                      :label="$t('max_age')"
                                                                      dense
                                                                      outlined
                                                                      type="Number"
                                                                      clearable>

                                                        </v-text-field>
                                                    </v-col>

                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-btn color="primary" dark v-on:click="searchInputAgeReport" class="mr-1">
                                                        <i class="material-icons">search</i>{{$t('search')}}
                                                    </v-btn>
                                                    <v-btn color="error" dark @click="clearInputAgeReport">
                                                        <i class="material-icons">clear</i>{{$t('clear')}}
                                                    </v-btn>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        Age Wise Customer in {{ branchInformation.branchName }}
                                    </h1>
                                    <h1 v-else class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        {{ branchInformation.branchNameNepali }}मा उमेर बुद्धिमान ग्राहक
                                    </h1>

                                    <template>
                                        <div id="app">
                                            <GChart type="BarChart"
                                                    :data="ageWiseReportData"
                                                    :options="chartOptions"
                                                    style="height: 400px;" />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-card class="pa-5" elevation="5">
                                    <v-expansion-panels hover>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row align="center" class="mt-4">
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-menu :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                class="col-sm-6 col-md-4"
                                                                min-width="290px"
                                                                offset-y
                                                                transition="scale-transition"
                                                                v-model="snackbar.menu2">
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field clearable
                                                                              :label="$t('select_date')"
                                                                              prepend-inner-icon="event"
                                                                              dense
                                                                              outlined
                                                                              readonly
                                                                              v-model="serverParams.dates"
                                                                              v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="serverParams.dates"></v-date-picker>

                                                        </v-menu>
                                                    </v-col>

                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-autocomplete v-model="serverParams.reportTypeIDs"
                                                                        :items="monthTypes"
                                                                        clearable
                                                                        dense
                                                                        outlined
                                                                        item-text="value"
                                                                        item-value="id"
                                                                        :label="$t('report_type')">
                                                        </v-autocomplete>
                                                    </v-col>

                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-btn color="primary" dark v-on:click="searchInputTicketSales" class="mr-1">
                                                        <i class="material-icons">search</i>{{$t('search')}}
                                                    </v-btn>
                                                    <v-btn color="error" dark @click="clearInputTicketSales">
                                                        <i class="material-icons">clear</i>{{$t('clear')}}
                                                    </v-btn>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                    <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        Ticket Sales in {{ branchInformation.branchName }}
                                    </h1>
                                    <h1 v-else class="headline mt-5 primary--text text-center"
                                        style="border-bottom: 1px solid grey">
                                        {{ branchInformation.branchNameNepali }}मा टिकट बिक्री
                                    </h1>

                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="ticketSalesReportData"
                                                    :options="chartOptions"
                                                    style="height: 400px;" />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-container>
            </template>
        </div>
    </div>
</template>
<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
    import axios from "axios";
    import CustomerForm from "../Customer/CustomerForm";
    import EmployeeForm from "../EmployeeInformation/create";
    import CustomerEditForm from "../Customer/CustomerEditForm";
    import deleteListData from "@/components/deleteModal";
    import { mapGetters } from "vuex";
    import publicAxios from "../../../simple-axios";
    export default {
        props: ["btn-text"],
        watch: {
            "serverParams.reportTypeID": {
                handler: function (val) {
                    if (val) {
                        this.loadItemsMuseumCustomerReport();
                        this.loadItemsMuseumTicketSalesReport();
                        this.loadItemsAgeWiseReport();
                    }
                },
            },
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(["getSystemUserData"]),
            isDivision() {
                return this.getSystemUserData.role === "Division";
            },
            isProvinceModule() {
                return this.getSystemUserData.role === "ProvinceModule";
            },
            isAssociation() {
                return this.getSystemUserData.role === "Association";
            },
            isBranchData() {
                return this.getSystemUserData.BranchID;
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
        },
        data: () => ({
            dataS: [],
            monthTypes: [
                { id: "1", value: "Yearly" },
                { id: "2", value: "Monthly" },
                { id: "3", value: "Daily" }
            ],
            options: {
                height: 450,
                is3d: true,
            },
            callbackResponse: {
                timeout: 6000,
            },
            columns: [
                {
                    label: "s_no",
                    field: "countIndex",
                },
                {
                    label: "full_name",
                    field: "fullName",
                },
                {
                    label: "gender",
                    field: "gender",
                },
                {
                    label: "nationality",
                    field: "nationality",
                },
                {
                    label: "ticket_no",
                    field: "ticketNumber",
                },
                {
                    label: "issued_date",
                    field: "issuedDate",
                },
                //{
                //  label: "Actions",
                //  field: "actions",
                //},
            ],
            empColumns: [
                {
                    label: "s_no",
                    field: "countIndex",
                },
                {
                    label: "employee_name",
                    field: "empName",
                },
                {
                    label: "employee_phone",
                    field: "empPhone",
                },
                {
                    label: "employee_address",
                    field: "empAddress",
                },
                {
                    label: "employee_email",
                    field: "empEmail",
                },
                {
                    label: "employee_type",
                    field: "empType",
                },

            ],
            rows: [],
            empRows: [],
            dialogDelete: false,
            deleteFormData: [],
            formdata: [],
            userList: [],
            customerData: {
                totalRows: 0,
                editItemIndex: null,
            },
            customerDatas: [],
            customerReportData: [],
            ticketSalesReportData: [],
            monthlyCustomerVisitCountrywiseData: [],
            ageWiseReportData: [],
            serverParams: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "personInformationID",
                        type: "desc",
                    },
                ],
                page: 1,
                perPage: 10,
            },
            isLoading: false,
            snackbar: {
                color: "",
                snackbar: false,
                menu: false,
                menu2: false,
                text: "",
                timeout: 6000,
                isLoading: false,
                formModal: false,
                eformModal: false,
                createFormComponent: false,
                createEmployeeComponent: false,
                editFormComponent: false,
                editEmployeeComponent: false,
                breadcrumbsItem: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        to: "MuseumDashboard",
                        exact: true,
                    },
                    {
                        text: "Musuem Customer Information",
                        disabled: true,
                    },
                ],
                dynamicComponent: {
                    current: "CustomerForm",
                    employee:"EmployeeForm"
                },
            },

            hotelBranchId: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [],
            branchInformation: [],
            barsData: [],
            RoomData: [],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead,
        }),
        components: {
            'CustomerForm': CustomerForm,
            'EmployeeForm': EmployeeForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData,
        },

       async mounted() {
            await this.getCompleteData();
            await this.loadItemsMuseumCustomerReport();
           await this.loadItemsMuseumTicketSalesReport();
           await this.loadItemsAgeWiseReport();
    
        },
        methods: {
            clearInput() {
                this.serverParams.reportTypeID = null
                this.serverParams.date = null
                this.loadItems();
                this.loadItemsMuseumCustomerReport();
            },
            clearInputTicketSales() {
                this.serverParams.reportTypeID = null
                this.serverParams.date = null
                this.loadItems();
                this.loadItemsMuseumTicketSalesReport();
            },
            searchInput() {
                this.loadItemsMuseumCustomerReport();

            },
            searchInputTicketSales() {
                this.loadItemsMuseumTicketSalesReport();

            },
            searchInputAgeReport() {
                this.loadItemsAgeWiseReport();
            },
            clearInputAgeReport() {
                this.serverParams.minAge = null
                this.serverParams.maxAge = null
                this.loadItems();
                this.loadItemsAgeWiseReport();
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            getCompleteData() {
                this.loadItems();
                this.dashboardData();
                this.getPieMuseumMonthlyPerformance();
                this.getMuseumCustomerCountryWise();
                this.getMuseumCustomerMonthlyWise();
                this.getMuseumCustomerReport();
                this.getBranchEmployeeDetails();
                this.getBarMuseumMonthlyCountrywiseVisit();
                this.formData();
            },
            formResponse(data) {
                console.log({ data });
                if (data.message) {
                    this.snackbar.formModal = false;
                    this.snackbar.eformModal = false;
                    this.snackbar.createFormComponent = false;
                    this.snackbar.createEmployeeComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true;
                    this.loadItems();
                    this.reloadPage();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.snackbar.createEmployeeComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false;
                    this.snackbar.eformModal = false;
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false;
                    this.loadItems();
                    this.loadItemsMuseumCustomerReport();
                    this.loadItemsMuseumTicketSalesReport();

                } else {
                    this.dialogDelete = false;
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null;
                this.snackbar.formModal = false;
                this.snackbar.eformModal = false;
                this.snackbar.createFormComponent = false;
                this.snackbar.createEmployeeComponent = false;
                this.snackbar.editFormComponent = false;
                this.snackbar.editEmployeeComponent = false;
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber,
                };
                //let eparam = {
                //    PageSize: this.eserverParams.perPage,
                //    PageNo: this.eserverParams.page,
                //    OrderType: this.eserverParams.sort[0].type,
                //    OrderBy: this.eserverParams.sort[0].field,
                //    EmpName: this.eserverParams.empName,
                //};
                axios
                    .post("museum/GetDailyCustomerInformationListAsync", param)
                    .then((response) => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                //axios
                //    .post("Employee/GetEmployeeDetailListAsync", eparam)
                //    .then((response) => {
                //        this.empRows = response.data.data;
                //        this.totalRecords = response.data.totalCount;
                //        this.snackbar.loading = false;
                //        console.log("this.employeeRows", this.empRows);
                //    })
                //    .catch((err) => {
                //        console.log(err);
                //    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
                this.loadItemsMuseumCustomerReport();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
                this.loadItemsMuseumCustomerReport();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
                this.loadItemsMuseumCustomerReport();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            addEmployee() {
                this.snackbar.eformModal = true
                this.snackbar.createEmployeeComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            editEmployee(item) {
                this.snackbar.eformModal = true
                this.snackbar.editEmployeeComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Museum/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },
            deleteEmp(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Employee/DeleteEmployeeDetailByIDAsync/" + props.personInformationID;
            },
            reloadPage() {
                this.getCompleteData();
                this.reload += 1;
                //this.$router.go();
            },

            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );

                this.branchInformation = dashboardData.data;
                console.log("this.branchDetails", this.branchInformation);
            },

            async getPieMuseumMonthlyPerformance() {
                const { data } = await publicAxios.post(
                    "museum/GetMuseumCustomerNationalandForeignVisitorAsync"
                );
                let formatedData = this.getChartData(data);
                this.dataS = formatedData;
                console.log("this.PieChartData", this.dataS);
            },
            async getMuseumCustomerCountryWise() {
                const { data } = await publicAxios.get(
                    "museum/GetMusuemCustomerCountrywisecountAsync"
                );
                let formatedData = this.getChartData(data);
                this.barsData = formatedData;
                console.log("this.HotelCustomerCountrywisecountAsync", this.barsData);
            },

            async getMuseumCustomerMonthlyWise() {
                const { data } = await publicAxios.get(
                    "museum/GetMuseumCustomerMonthWise"
                );
                let formatedData = this.getChartData(data);
                this.customerDatas = formatedData;
                console.log("this.GetNationalParkCustomerMonthWise", this.customerDatas);
            },
           
            async getUserDetails() {
                const { data } = await publicAxios.post("Museum/UserList");
                this.userList = data;
                console.log("this.userlist", this.userList);
            },
            async getBarMuseumMonthlyCountrywiseVisit() {
                const { data } = await publicAxios.get('Aviation/GetHAirCountryWiseTouristVisitDetails')
                let formatedData = this.getChartData(data)
                this.monthlyCustomerVisitCountrywiseData = formatedData
                console.log('this.getMuseumMonthlyCountryWiseTouristVisitDetails', this.monthlyCustomerVisitCountrywiseData)
            },
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportEmployeeDetails/0"
                );
                this.empRows = data;
                console.log("this.employeeRows", this.empRows);
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },

            async loadItemsMuseumCustomerReport() {
                    this.isLoading = true
                    let param = {

                        ReportTypeID: this.serverParams.reportTypeID != null && this.serverParams.reportTypeID != undefined ? parseInt(this.serverParams.reportTypeID) : 0,
                        Date: this.serverParams.date,
                };
                   await axios.post('museum/GetMuseumCustomerReport', param)
                        .then(response => {

                            this.customerReportDataList = response.data;
                            this.isLoading = false
                        })
                        .catch(err => {
                            console.log(err)
                        })

                    let counta = this.customerReportDataList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.customerReportData = [];
                    } else {
                        let formatedData = this.getChartData(this.customerReportDataList);
                        this.customerReportData = formatedData;
                    }
            },
            async loadItemsMuseumTicketSalesReport() {
                    this.isLoading = true
                    let param = {

                        ReportTypeID: this.serverParams.reportTypeIDs != null && this.serverParams.reportTypeIDs != undefined ? parseInt(this.serverParams.reportTypeIDs) : 0,
                        Date: this.serverParams.dates,
                    };
                   await axios.post('museum/GetMuseumTicketSalesReport', param)
                        .then(response => {

                            this.ticketSalesReportDataList = response.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    let counta = this.ticketSalesReportDataList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.ticketSalesReportData = [];
                    } else {
                        let formatedData = this.getChartData(this.ticketSalesReportDataList);
                        this.ticketSalesReportData = formatedData;
                    }
            },
            async loadItemsAgeWiseReport() {
                    this.isLoading = true
                    let param = {

                        MinAge: this.serverParams.minAge != null && this.serverParams.minAge != undefined ? parseInt(this.serverParams.minAge) : 0,
                        MaxAge: this.serverParams.maxAge != null && this.serverParams.maxAge != undefined ? parseInt(this.serverParams.maxAge) : 0,
                    };
                   await axios.post('museum/GetMuseumAgeWiseReport', param)
                        .then(response => {

                            this.ageWiseReportDataList = response.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    let counta = this.ageWiseReportDataList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.ageWiseReportData = [];
                    } else {
                        let formatedData = this.getChartData(this.ageWiseReportDataList);
                        this.ageWiseReportData = formatedData;
                    }
            },
        },
        created() {
            this.getMuseumCustomerCountryWise();
            this.getMuseumCustomerMonthlyWise();
            this.loadItemsMuseumCustomerReport();
            this.getBranchEmployeeDetails();
            this.getBarMuseumMonthlyCountrywiseVisit();
            this.loadItemsMuseumTicketSalesReport();
            this.loadItemsAgeWiseReport();
            this.getUserDetails();
            this.dashboardData();
            this.getPieMuseumMonthlyPerformance();
        },
    };
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }

    @media screen and (min-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        .boxIcon {
            font-size: 36px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 375px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        #table {
            font-size: 12px !important;
        }

        .boxIcon {
            font-size: 26px;
        }

        #hotellogo {
            width: 890px;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 375px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1263px) {
        #sideBox {
            font-size: 12px;
            color: white;
        }

        .boxIcon {
            font-size: 20px;
        }

        #box {
            height: 305px;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 80px;
        }

        #table {
            font-size: 12px !important;
        }

        #hotellogo {
            width: 650px;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }


    @media screen and (max-width:979px) {
        #sideBox {
            font-size: 10px;
            color: white;
        }

        #sideMainBox {
            height: 70px;
        }

        #box {
            height: 270px;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 70px;
        }
    }

    @media screen and (max-width: 860px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
            padding: 25px;
        }

        .boxIcon {
            /*  font-size: 26px;
            margin-bottom:15px;*/
            display: none;
        }

        #titleForBox {
            color: white;
            display: block;
            text-align: center;
        }

        #box {
            width: 100%;
            height: 320px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width: 750px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
        }


        #box {
            width: 100%;
            height: 350px;
        }

        #hotellogo {
            width: 100%;
            height: 450px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width:640px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 350px;
            object-fit: fill;
        }
    }


    @media screen and (max-width:599px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 250px;
            object-fit: fill;
        }
    }
</style>








